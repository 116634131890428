import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const vnext: IPartnerAppSettings = {
    serviceEndPoints: [
        {
          serviceEndPoint: 'https://oneprofiledailyapi.azurewebsites.net/',
          clientId: '71a156e9-6196-4afe-aa95-4ca2a00790ca',
        },
        {
          serviceEndPoint: 'https://oneprofiledailyapipack.azurewebsites.net/',
          clientId: '71a156e9-6196-4afe-aa95-4ca2a00790ca',
        },
        {
          serviceEndPoint: 'https://oneprofileuatcoreapi.microsoft.com/',
          clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
        },
        {
          serviceEndPoint: 'https://mscuatservicesuwebapi.azurewebsites.net/',
          clientId: '77eb98a2-d1f5-4a55-a555-42441c85edcb'
        },
        {
          serviceEndPoint: 'https://ps-sms-api-int.azurewebsites.net/',
          clientId: '38578c27-d821-45d3-95db-fcf0e8371e68'
        },
        {
          serviceEndPoint: 'https://eventstore-sit-eus.azurewebsites.net/',
          clientId: '6f40053e-5319-40e5-a90b-6f714506d96d'
        },
        {
          serviceEndPoint: 'https://feedback-api-preprod-eus.azurewebsites.net/',
          clientId: '6f40053e-5319-40e5-a90b-6f714506d96d'
        },
        {
          serviceEndPoint: 'https://servicesuat.microsoft.com/rstaff/',
          clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15'
        },
        {
          serviceEndPoint: 'https://esxp-uat.azurefd.net',
          clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15'
        },
        {
          serviceEndPoint: 'https://servicesuat.microsoft.com/rmavl/',
          clientId: '6f40053e-5319-40e5-a90b-6f714506d96d'
        },
        {
          serviceEndPoint: 'https://pslakedsdev.azurewebsites.net/mwb/',
          clientId: '6f40053e-5319-40e5-a90b-6f714506d96d'
        },
        {
          serviceEndPoint: 'https://skillservicesuat.microsoft.com/',
          clientId: '6f40053e-5319-40e5-a90b-6f714506d96d'
        },
        {
          serviceEndPoint: 'https://pslakedsdev.azurewebsites.net/',
          clientId: 'c5779b49-c238-45cb-8974-36958d4c7d96'
        },
        {
          serviceEndPoint: 'https://managerdashboardapi.azurewebsites.net/',
          clientId: '6f40053e-5319-40e5-a90b-6f714506d96d'
        }
    ],
    cdnBaseUrl: 'https://oneprofileditcdnv2.azureedge.net/dev',
    HOSTNAME: 'https://oneprofileditcdnv2.azureedge.net/dev/',
    envName: 'vnext',
    baseUrl: 'https://oneprofiledailyapipack.azurewebsites.net/',
    availabilityBaseUrl : 'https://servicesuat.microsoft.com/rmavl/',
    insightsPsLakeBaseUrl: 'https://pslakedsdev.azurewebsites.net/',
    skillServiceUrl: 'https://skillservicesuat.microsoft.com/',
    managerDashboardUrl: 'https://managerdashboardapi.azurewebsites.net/',
    claimsUrl: 'https://oneprofiledailyclaims.azurewebsites.net/',
    skillUrl: 'https://oneprofiledailyapi.azurewebsites.net/',
    servicesUCDN: 'https://servicesuuatendpoint.azureedge.net/',
    servicesUAPIBaseUrl: 'https://mscuatservicesuwebapi.azurewebsites.net/',
    coreApiUrl: 'https://oneprofileuatcoreapi.microsoft.com/',
    grmReadApiUrl: 'https://esxp-uat.azurefd.net/grm/read/api/Search',
    grmAzureApiUrl: 'https://ps-sms-api-int.azurewebsites.net/api/v1/Search/grmsit/grm-assignmentsearch-sit-index',
    fxpPafApiUrl: 'https://eventstore-sit-eus.azurewebsites.net/api/notifications',
    feedbackServiceBaseUrl: 'https://feedback-api-preprod-eus.azurewebsites.net/api/v1/',
    feedbackServiceSubscriptionId: '3593089c-1432-44a9-b4e4-7895d682d4d1',
    AxisUrl: 'https://axissitweb.azurewebsites.net/',
    CompassUrl: 'https://professionalservicesint.microsoft.com/o2c/dealmgmt/sit/',
    helpCentralStaffingArticleUrl: 'https://helpcentralsitwestus.azurewebsites.net/#/tenant/ES/article/ESXP_f1e531cf-5bfa-4fc4-a956-39edd966749f',
    cdnFAQArticleUrl: 'https://aka.ms/JobBoardToolsFAQ',
    salesAccessForAll : false,
    displayServicesU: false,
    managerEndorsementFlag: true,
    feedbackIdMapping: {
        opdashboard: '08ba18c9-09ea-483c-a8f1-cc2ad93e90a4',
        expressinterest: '8188568e-2afe-4fe8-9c82-43a75232893f',
        recommendation: 'd8fa96b0-60f5-4238-9a73-aba10d30d27c'
    },
    jobBoardFeatureFlagEnabled: true,
    ExploreFeatureFlagEnabled: true,
    SubconV3FeatureFlagEnabled: true,
    webUrl: 'https://servicesuat.microsoft.com/#/',
    csaBuisnessRoleIdToBlockSA:161,
    e360SkillsUrl: 'https://e360ppe-h4hcf6dqcqceg3f8.z01.azurefd.net/skills',
    e360AccreditationsUrl: 'https://e360ppe-h4hcf6dqcqceg3f8.z01.azurefd.net/skills/accreditations',
    engage360BaseUrl: 'https://e360ppe-h4hcf6dqcqceg3f8.z01.azurefd.net/',
    e360CertificationsUrl: 'https://e360ppe-h4hcf6dqcqceg3f8.z01.azurefd.net/skills/certifications',
    msxBaseUrl: "https://msxuat.crm.dynamics.com/",
    accreditationPortalUrl: "https://microsoft.sharepoint.com/teams/ServicesAccreditation",
  };
