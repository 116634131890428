export const prod = {
  serviceEndPoints: [
    {
      serviceEndPoint: 'https://oneprofileprodclaims.trafficmanager.net/',
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
    },
    {
      serviceEndPoint: 'https://oneprofileprodapi.microsoft.com/',
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
    },
    {
      serviceEndPoint: 'https://oneprofileprodapipack.azurefd.net/',
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
    },
    {
      serviceEndPoint: 'https://oneprofileprodcoreapi.microsoft.com/',
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
    },
    {
      serviceEndPoint: 'https://api.skillscredentialing.microsoft.com/',
      clientId: '5849ca7e-5c37-4dd1-8684-00485a2fb73c'
    },
    {
      serviceEndPoint: 'https://esxp.microsoft.com/api/v1/Search/',
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a'
    },
    {
      serviceEndPoint: 'https://eventstore-sit-eus.azurewebsites.net/',   // TODO-vnext-changeurl
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d'
    },
    {
      serviceEndPoint: 'https://esxp.microsoft.com/rstaff/',
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a'
    },
    {
      serviceEndPoint: 'https://esxp-prod.azurefd.net',
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a'
    },
    {
      serviceEndPoint: 'https://esxp.microsoft.com/rmavl/',
      clientId: 'bc72c2f2-9689-42a0-aa49-f579509c867b'
    },
    {
      serviceEndPoint: 'https://skillservicesprod.microsoft.com/',
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9'
    },
    {
      serviceEndPoint: 'https://pslakeds.azurewebsites.net/mwb/api/UtilizationByAlias',
      clientId: 'e03cd7c9-8c94-45dd-b4b8-68957267ec20'
    },
    {
      serviceEndPoint: 'https://pslakeds.azurewebsites.net/',
      clientId: 'e03cd7c9-8c94-45dd-b4b8-68957267ec20'
    },
    {
      serviceEndPoint: 'https://managerdashboardapi.azurewebsites.net/',
      clientId: 'cc1b9d80-0cfd-42dc-aea1-c610b6393ab9'
    }
  ],
  cdnBaseUrl: 'https://opsesxpprodcdnvnext.azureedge.net', // TODO-vnext-changeurl
  HOSTNAME: 'https://opsesxpprodcdnvnext.azureedge.net',  // TODO-vnext-changeurl
  envName: 'prod',
  baseUrl: 'https://oneprofileprodapipack.azurefd.net/',
  availabilityBaseUrl: 'https://esxp.microsoft.com/rmavl/',
  insightsPsLakeBaseUrl: 'https://pslakeds.azurewebsites.net/',
  skillServiceUrl: 'https://skillservicesprod.microsoft.com/',
  managerDashboardUrl: 'https://managerdashboardapi.azurewebsites.net/',
  claimsUrl: 'https://oneprofileprodclaims.trafficmanager.net/',
  skillUrl: 'https://oneprofileprodapi.microsoft.com/',
  servicesUCDN: 'https://mscservicesuwebapi.azureedge.net/',
  servicesUAPIBaseUrl: 'https://api.skillscredentialing.microsoft.com/',
  coreApiUrl: 'https://oneprofileprodcoreapi.microsoft.com/',
  grmAzureApiUrl: 'https://esxp.microsoft.com/api/v1/Search/grmprod/grm-assignmentsearch-prod-index',
  grmReadApiUrl: 'https://esxp-prod.azurefd.net/grm/read/api/Search',
  fxpPafApiUrl: 'https://eventstore-api-prod.azurefd.net/api/webnotifications',
  feedbackServiceBaseUrl: 'https://feedbackservice.trafficmanager.net/api/v1/',
  feedbackServiceSubscriptionId: '3593089c-1432-44a9-b4e4-7895d682d4d1',
  AxisUrl: 'https://axis.microsoft.com/',
  CompassUrl: 'https://professionalservices.microsoft.com/dealmgmt/',
  helpCentralStaffingArticleUrl: 'https://helpcentral.microsoft.com/#/tenant/ES/article/ESXP_92d3b284-2bb3-4267-953e-a9d384863436',
  cdnFAQArticleUrl: 'https://aka.ms/JobBoardToolsFAQ',
  salesAccessForAll: false,
  displayServicesU: false,
  managerEndorsementFlag: true,
  feedbackIdMapping: {
    opdashboard: 'f872eea4-a586-482e-be90-8ebc613f462c',
    expressinterest: '47b03550-8575-4326-99f0-c6438c1cff58',
    recommendation: 'ffbc8928-58e3-48fa-a243-0993e3397263',
    csaskills: 'bdfd738d-997e-4fb3-9bb3-d64fad0d1966'
  },
  jobboardNewExperienceEnabled: false,
  jobBoardFeatureFlagEnabled: true,
  ExploreFeatureFlagEnabled: false,
  SubconV3FeatureFlagEnabled: false,
  webUrl: 'https://esxp.microsoft.com/#/',
  csaBuisnessRoleIdToBlockSA: 159,
  e360SkillsUrl: 'https://engage360.microsoft.com/skills',
  e360AccreditationsUrl: 'https://engage360.microsoft.com/skills/accreditations',
  engage360BaseUrl: 'https://engage360.microsoft.com/',
  e360CertificationsUrl: 'https://engage360.microsoft.com/skills/certifications',
  msxBaseUrl: "https://microsoftsales.crm.dynamics.com/",
  accreditationPortalUrl: "https://microsoft.sharepoint.com/teams/ServicesAccreditation",
};
