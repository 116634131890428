import { Environment } from "@fxp/fxpservices";
import { dev } from "./appSettings.dev";
import { sit } from "./appSettings.sit";

import { prod } from "./appSettings.prod";
import { uat } from "./appSettings.uat";
import { vnext } from "./appSettings.vnext";
import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const appSettings = () => {
  let appSetting: IPartnerAppSettings;

  if(window["opScriptUrl"] != null && window['opScriptUrl'].startsWith('http://localhost')){
    return dev;
  }
  switch (window["FxpAppSettings"].EnvironmentName) {
    case Environment.Dev:
      appSetting = dev;
      break;
    case Environment.Sit:
      if (window.location.host === "fxp-vnext.azurewebsites.net") {
        appSetting = vnext;
      } else {
        appSetting = sit;
      }
      break;
    case Environment.AuroraSit:
    case Environment.Uat:
      appSetting = uat;
      break;
    case Environment.Prod:
      appSetting = prod;
      break;
    case "local":
      appSetting = dev;
      break;
    default:
      appSetting = dev;
      break;
  }
  return appSetting;
};
