import { IAppService, IFxpAppContext, IServiceEndPoints, IPartnerBundle, IRouteInfo, ISharedComponentConfig, ISharedComponents, IAppServiceWithSharedComponent, IAuthExceptionServiceEndPoints, Environment } from '@fxp/fxpservices';
import { appSettings } from './environments/config/appsettingsGenerator';
import { fxpTenantConfiguration } from './app/opux-core-services/services/commonServices/dataStore';
import { IOneProfileStateConfig } from './environments/interface/IPartnerAppSettings';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class OneProfileApplication_routes implements IAppService, IAppServiceWithSharedComponent {

    constructor() {
      const scriptSrc = document.currentScript as HTMLScriptElement;
      window['opScriptUrl'] = scriptSrc?.src;
    }

    getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    // addTileComponent("calender1",LazyTileComponent);

    const baseroute: IOneProfileStateConfig = {
      name: 'oneprofilevnext',
      url: '',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const errorroute: IOneProfileStateConfig = {
      name: 'opvnextCustomErrorPage',
      url: '/opvnextcustomErrorPage/:errorCode',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: 'Error',
        pageTitle: 'Error',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'ErrorPage',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const opdashboard: IOneProfileStateConfig = {
      name: 'oneprofilevnext.opdashboard',
      url: '/opdashboard/{alias:any}',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Resource360 Dashboard',
        pageTitle: 'Resource360 Dashboard',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Resource360',
        subFeatureName: 'Dashboard',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const convergedDashboard: IOneProfileStateConfig = {
      name: 'oneprofilevnext.csadashboard',
      url: '/csadashboard/{alias:any}',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'CSA Dashboard',
        pageTitle: 'CSA Dashboard',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'CSA Dashboard',
        subFeatureName: 'Dashboard',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };

    // Subcon onboarding V3
    // Only for development, will not be enabled till it goes live
    const subconPjmDashboardContainer: IOneProfileStateConfig = {
      name: 'subconPjmDashboardContainer',
      url: '/opsubcon/pjmdashboard/:projectId',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'OP Subcon dashboard',
        breadcrumbText: 'Subcon dashboard',
        pageTitle: 'Subcon dashboard',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext',
        extendable: {
          webComponent: 'opw-subcon-pjm-dashboard-page'
        }
      }
    };

    const subconManagerDashboardContainer: IOneProfileStateConfig = {
      name: 'subconManagerDashboardContainer',
      url: '/opsubcon/managerdashboard/:engagementId?packageId',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Subcon Manager Dashboard',
        breadcrumbText: 'Subcon Manager Dashboard',
        pageTitle: 'Subcon Manager Dashboard',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext',
        extendable: {
          webComponent: 'op-subcon-manager-dashboard-main'
        }
      }
    };

    const opSubconMain: IOneProfileStateConfig = {
      name: 'oneprofile.opsubcon',
      url: '',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Subcontractor Onboarding',
        breadcrumbText: 'Subcontractor Onboarding',
        pageTitle: 'Subcontractor Onboarding',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'SubconOnboarding',
        subFeatureName: 'SubconMain',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      },
      authorizationRules: {
      AllowedPermissions: {
        ResourcePermissions: {
          Value: [
            {
              Resource: 'PS.SubContractor',
              Permissions: { Value: ['Add'] },
            },
          ],
        },
      }
    }
    };

    const opSubconOnboarding: IOneProfileStateConfig = {
      name: 'oneprofile.opsubcon.onboarding',
      url: '/opsubcon/onboarding?emailId&source',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Subcontractor Onboarding',
        breadcrumbText: 'Subcontractor Onboarding',
        pageTitle: 'Subcontractor Onboarding',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'SubconOnboarding',
        subFeatureName: 'SubconOnboarding',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      },
      authorizationRules: {
        AllowedPermissions: {
          ResourcePermissions: {
            Value: [
              {
                Resource: 'PS.SubContractor',
                Permissions: { Value: ['Add'] },
              },
            ],
          },
        }
      }
    };

    const opjobboardNew = {
      name: 'rstaff-jobboard',
      url: '/jobboard?view',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Job Board',
        breadcrumbText: 'Job Board',
        pageTitle: 'Job Board',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext',
        extendable: {
          webComponent: 'opw-jb-page',
          props: {
            commentsOnExpressInterest: true,
            accreditationWithSkill: true
          }
        }
      }
    };

    const opjobboard: IOneProfileStateConfig = {
      name: 'oneprofilevnext.opjobboard',
      url: '',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Job Board',
        breadcrumbText: 'Job Board',
        pageTitle: 'Job Board',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const opmyjobboard: IOneProfileStateConfig = {
      name: 'oneprofilevnext.opjobboard.myboard',
      url: '/opjobboard/myboard/:alias?jobtype&source',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'My Job Board',
        breadcrumbText: 'My Job Board',
        pageTitle: 'My Job Board',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Jobboard',
        subFeatureName: 'MyBoard',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const oprecommendedjobs: IOneProfileStateConfig = {
      name: 'oneprofilevnext.opjobboard.recommended',
      url: '/opjobboard/recommended/:alias?source&reason',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Recommended Job',
        breadcrumbText: 'Recommended Jobs',
        pageTitle: 'Recommended Job',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Jobboard',
        subFeatureName: 'JobRecommendation',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const opexplorejobs: IOneProfileStateConfig = {
      name: 'oneprofilevnext.opjobboard.explore',
      url: '/opjobboard/explore/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Explore Job',
        breadcrumbText: 'Explore Job',
        pageTitle: 'Explore Job',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Jobboard',
        subFeatureName: 'Explore',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const projecthistorydetails: IOneProfileStateConfig = {
      name: 'oneprofilevnext.projecthistorydetails',
      url: '/projecthistorydetails/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Project Engagements',
        pageTitle: 'Project Engagements',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Resource360',
        subFeatureName: 'ProjectEngagements',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const landingdashboard: IOneProfileStateConfig = {
      name: 'oneprofilevnext.oplandingdashboard',
      url: '/oplandingdashboard',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['OPCommon', 'CXP'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Dashboard',
        pageTitle: 'Landing Dashboard',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const managerdashboard: IOneProfileStateConfig = {
      name: 'oneprofilevnext.managerdashboard',
      url: '/managerdashboard',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['OPCommon', 'CXP'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Dashboard',
        pageTitle: 'Manager Dashboard',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const categoriesdashoard: IOneProfileStateConfig = {
      name: 'oneprofilevnext.categorydashboard',
      url: '/categorydashboard/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['OPCommon', 'CXP'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Skill categories',
        pageTitle: 'Skill Categories',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      },
    };
    const categoriestab: IOneProfileStateConfig = {
      name: 'oneprofilevnext.category',
      url: '',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['OPCommon', 'CXP'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Skill categories',
        pageTitle: 'Skill Categories',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      },
    };
    const categoriesnav: IOneProfileStateConfig = {
      name: 'oneprofilevnext.category.id',
      url: '/category/:alias/{id:any}',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['OPCommon', 'CXP'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Skill navigation',
        pageTitle: 'Skill Navigation',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      },
    };
    const certifications: IOneProfileStateConfig = {
      name: "oneprofilevnext.certifications",
      url: "/Certifications/{alias:any}",

      applicableTenants: [
        "ES",
        "CSS",
        "OPCommon",
        "CXP",
        "MCS",
        "CES",
        "OTHER",
      ],
generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      data: {
        headerName: "Profile Management",
        breadcrumbText: "Certifications",
        pageTitle: "Certifications-Profile",
        partnerTelemetryName: "OneProfilevNext",
        appNameForTelemetry: "OneProfile",
        featureName: "Resource360",
        subFeatureName: "Certifications",
        partnerAppName: "OneProfilevNext",
      },
    };

    const createprofile: IOneProfileStateConfig = {
      name: "oneprofilevnext.createprofile",
      url: "/CreateUserProfile",

      applicableTenants: [
        "ES",
        "CSS",
        "OPCommon",
        "CXP",
        "MCS",
        "CES",
        "OTHER",
      ],
generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      data: {
        headerName: "Profile Management",
        breadcrumbText: "createUserProfile",
        pageTitle: "createUserProfile",
        partnerTelemetryName: "OneProfilevNext",
        appNameForTelemetry: "OneProfile",
        featureName: "Resource360",
        subFeatureName: "CreateUserProfile",
        partnerAppName: "OneProfilevNext",
      },
    };

    const accreditMgmt: IOneProfileStateConfig = {
      name: "oneprofilevnext.accreditMgmt",
      url: "/rmBulkOperations/{alias:any}",

      applicableTenants: [
        "ES",
        "CSS",
        "OPCommon",
        "CXP",
        "MCS",
        "CES",
        "OTHER",
      ],
generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      data: {
        headerName: "Accreditation Management",
        breadcrumbText: "Accreditation Management",
        pageTitle: "Accreditation Management",
        partnerTelemetryName: "OneProfilevNext",
        appNameForTelemetry: "OneProfile",
        featureName: "Resource360",
        subFeatureName: "Accreditation Management",
        partnerAppName: "OneProfilevNext",
      },
    };

    const autoAccreditMgmt: IOneProfileStateConfig = {
      name: "oneprofilevnext.autoAccreditMgmt",
      url: "/AccreditationsManagement/{alias:any}",

      applicableTenants: [
        "ES",
        "CSS",
        "OPCommon",
        "CXP",
        "MCS",
        "CES",
        "OTHER",
      ],
generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      data: {
        headerName: "Auto Accreditation Management",
        breadcrumbText: "Auto Accreditation Management",
        pageTitle: "Auto Accreditation Management",
        partnerTelemetryName: "OneProfilevNext",
        appNameForTelemetry: "OneProfile",
        featureName: "Resource360",
        subFeatureName: "Auto Accreditation Management",
        partnerAppName: "OneProfilevNext",
      },
    };

    const updateUser: IOneProfileStateConfig = {
      name: "oneprofilevnext.updateUser",
      url: "/UpdateUserProfile",

      applicableTenants: [
        "ES",
        "CSS",
        "OPCommon",
        "CXP",
        "MCS",
        "CES",
        "OTHER",
      ],
generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      data: {
        headerName: "Update User",
        breadcrumbText: "Update User",
        pageTitle: "Update User",
        partnerTelemetryName: "OneProfilevNext",
        appNameForTelemetry: "OneProfile",
        featureName: "Resource360",
        subFeatureName: "Update User",
        partnerAppName: "OneProfilevNext",
      },
    };

    const accreditations: IOneProfileStateConfig = {
      name: "oneprofilevnext.accreditations",
      url: "/Accreditations/{alias:any}",

      applicableTenants: [
        "ES",
        "CSS",
        "OPCommon",
        "CXP",
        "MCS",
        "CES",
        "OTHER",
      ],
generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      data: {
        headerName: "Profile Management",
        breadcrumbText: "Accreditations",
        pageTitle: "Accreditations-Profile",
        partnerTelemetryName: "OneProfilevNext",
        appNameForTelemetry: "OneProfile",
        featureName: "Resource360",
        subFeatureName: "Accreditations",
        partnerAppName: "OneProfilevNext",
      },
    };

    const skillassessment: IOneProfileStateConfig = {
      name: 'oneprofilevnext.skillassessment',
      url: '/skillassessment/{alias:any}?viewMode&category&jobskillid&state',

      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER', 'SALES'],
generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Skill Assessment',
        pageTitle: 'Skill Assessment',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Resource360',
        subFeatureName: 'SkillAssessment',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const primaryskill: IOneProfileStateConfig = {
      name: 'oneprofilevnext.primaryskill',
      url: '/primaryskill/:alias/{categoryid:any}',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Primary Skill',
        pageTitle: 'Primary Skill',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Resource360',
        subFeatureName: 'PrimarySkill',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const oppartnerskillsearchgrmconsumption: IOneProfileStateConfig = {
      name: 'oneprofilevnext.partnerskillsearchgrmconsumption',
      url: '/oppartnerskillsearchgrmconsumption/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const oppmcsskillmgmtconsumer: IOneProfileStateConfig = {
      name: 'oneprofilevnext.oppmcsskillmgmtconsumer',
      url: '/opmcs/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext'
      }
    };

    const managerwbskillsconsumer: IOneProfileStateConfig = {
      name: 'oneprofilevnext.managerwbskillsconsumer',
      url: '/mwbskills/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Skills Management',
        breadcrumbText: '',
        pageTitle: 'Manager Workbench Skills',
        partnerTelemetryName: 'OneProfilevNext'
      }
    };

    const mwbrecommendationflyoutconsumer: IOneProfileStateConfig = {
      name: 'oneprofilevnext.mwbrecommendationflyoutconsumer',
      url: '/mwbrecomm/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Skills Management',
        breadcrumbText: '',
        pageTitle: 'Manager Workbench Skills',
        partnerTelemetryName: 'OneProfilevNext'
      }
    };

    const oppcesskillmgmtconsumer: IOneProfileStateConfig = {
      name: 'oneprofilevnext.oppcesskillmgmtconsumer',
      url: '/skillassessment/:alias?openAccordionCategory',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER', 'SALES'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Skill Assessment',
        pageTitle: 'Skill Assessment',
        partnerTelemetryName: 'OneProfilevNext'
      }
    };

    const quickskillassessment: IOneProfileStateConfig = {
      name: 'oneprofilevnext.quickskillassessment',
      url: '/quickskillassessment/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Quick Skill Assessment',
        pageTitle: 'Quick Skill Assessment',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'Resource360',
        subFeatureName: 'SkillRecommendation',
        actionName: 'View',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const skillrating: IOneProfileStateConfig = {
      name: 'oneprofilevnext.category.skillrating',
      url: '/category/skillrating/:alias/{name:any}',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['OPCommon', 'CXP'],
      data: {
        headerName: 'Profile Management',
        breadcrumbText: 'Skill Rating',
        pageTitle: 'Skill Rating',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      },
    };

    // ----------------------------shared--------------
    const grmtile: IOneProfileStateConfig = {
      name: 'opgrmtile',
      url: '/opdashboard/grm',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const certtile: IOneProfileStateConfig = {
      name: 'opcerttile',
      url: '/opdashboard/op',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const skilltile: IOneProfileStateConfig = {
      name: 'opskilltile',
      url: '/opdashboard/op',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const projecttile: IOneProfileStateConfig = {
      name: 'opprojecttile',
      url: '/opdashboard/op',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const quicklinktile: IOneProfileStateConfig = {
      name: 'opquicklinktile',
      url: '/opdashboard/op',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };
    const alerttile: IOneProfileStateConfig = {
      name: 'opalerttile',
      url: '/opdashboard/op',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const wcContainer: IOneProfileStateConfig = {
      name: 'opwccontainer',
      url: '/opwc/:component',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'OP wc container',
        breadcrumbText: 'wc',
        pageTitle: 'op wc container',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const jobboardtile: IOneProfileStateConfig = {
      name: 'opjobboardtile',
      url: '/opdashboard/op',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const oppartnerskillsearch: IOneProfileStateConfig = {
      name: 'opPartnerSkillSearch',
      url: '/oppartnerskillsearch/:alias',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: '',
        breadcrumbText: '',
        pageTitle: '',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    // Taxonomy Deployment
    const optaxonomydeployment: IOneProfileStateConfig = {
      name: 'oneprofile.opTaxonomyDeployment',
      url: '/optaxydeploy',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Taxonomy Deloyment',
        breadcrumbText: 'Taxonomy Deloyment',
        pageTitle: 'Taxonomy Deloyment',
        partnerTelemetryName: 'OneProfilevNext',
        appNameForTelemetry: 'OneProfile',
        featureName: 'TaxonomyDeloyment',
        subFeatureName: 'TaxonomyDeloyment',
        partnerAppName: 'OneProfilevNext'
      }
    };

    // ----------------------Admin---------------------
    const profileupdate: IOneProfileStateConfig = {
      name: 'oneprofilevnext.profileupdate',
      url: '/adminprofileupdate',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Admin ProfileUpdate',
        breadcrumbText: 'ProfileUpdate',
        pageTitle: 'Admin ProfileUpdate',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const componentViewer: IOneProfileStateConfig = {
      name: 'componentViewer',
      url: '/cview',

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
      applicableTenants: ['ES', 'CSS', 'OPCommon', 'CXP', 'MCS', 'CES' , 'OTHER'],
      data: {
        headerName: 'Component Viewer',
        breadcrumbText: 'Component Viewer',
        pageTitle: 'Component Viewer',
        partnerTelemetryName: 'OneProfilevNext',
        partnerAppName: 'OneProfilevNext'
      }
    };

    const allRoutes = [
      baseroute,
      categoriestab,
      categoriesnav,
      categoriesdashoard,
      skillrating,
      landingdashboard,
      managerdashboard,
      errorroute,
      convergedDashboard,
      opdashboard,
      skillassessment,
      primaryskill,
      projecthistorydetails,
      quickskillassessment,
      grmtile,
      certtile,
      skilltile,
      alerttile,
      quicklinktile,
      projecttile,
      profileupdate,
      oppartnerskillsearchgrmconsumption,
      oppartnerskillsearch,
      opjobboard,
      optaxonomydeployment,
      wcContainer,
      oppmcsskillmgmtconsumer,
      managerwbskillsconsumer,
      opSubconMain,
      opSubconOnboarding,
      subconPjmDashboardContainer,
      subconManagerDashboardContainer,
      oppcesskillmgmtconsumer,
      mwbrecommendationflyoutconsumer,
      certifications,
      accreditations,
      createprofile,
      accreditMgmt,
      autoAccreditMgmt,
      updateUser,
    ];

    if (window['FxpAppSettings'].EnvironmentName !== Environment.Prod) {
      allRoutes.push(componentViewer);
    }

    if (appSettings().jobBoardFeatureFlagEnabled === true) {

        opjobboard.redirectTo = 'rstaff-jobboard';
        oprecommendedjobs.redirectTo = 'rstaff-jobboard';
        opmyjobboard.redirectTo = 'rstaff-jobboard';
       if (appSettings().jobboardNewExperienceEnabled) {
        allRoutes.push(opjobboardNew);
       }
      allRoutes.push(opjobboard);
      allRoutes.push(opmyjobboard);
      allRoutes.push(oprecommendedjobs);
      allRoutes.push(jobboardtile);
    }

    if (appSettings().ExploreFeatureFlagEnabled === true) {
      allRoutes.push(opexplorejobs);
    }


    const routeInfo: IRouteInfo = {
      applicationName: 'oneprofileapplication',
      sharedBundles: [],
      routes: allRoutes.filter(this.routesFilter)
    };

    return routeInfo;
  }

  routesFilter(route: IOneProfileStateConfig) {
    const tenantId: string = fxpTenantConfiguration.TenantId;
    const applicableTenants: string[] = route.applicableTenants ?? [];
    return applicableTenants.some(c => c === tenantId);
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  public getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundles = [
      {
        name: 'oneprofile-app-bundle',
        files: [
          `${baseUrl}/vendor.bundle.js`,
          `${baseUrl}/styles.bundle.js`,
          `${baseUrl}/main.bundle.js`
        ],
        sequentialLoading: true
      }
    ];
    return bundles;
  }

  getAuthExceptionEndpoints(): IAuthExceptionServiceEndPoints[] {
    return [];
  }

  getSharedComponentsInfo(): ISharedComponents  {
    const grmCalendarSharedComp: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders GRM calendar.',
    componentInputs: [['isActiveFlex' , 'For tile API call'], ['isExpandedFlex', 'For hiding content']],
    componentOutputs: [],
    componentName: 'opgrmtile'
  };
  const opCertSharedComp: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders OP Cert Tile.',
    componentInputs: [['isActiveFlex' , 'For tile API call'], ['isExpandedFlex', 'For hiding content']],
    componentOutputs: [],
    componentName: 'opcerttile'
  };
  const opSkillSharedComp: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders OP Skill Tile.',
    componentInputs: [['isActiveFlex' , 'For tile API call'], ['isExpandedFlex', 'For hiding content']],
    componentOutputs: [],
    componentName: 'opskilltile'
  };
  const opAlertSharedComp: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders OP Alert Tile.',
    componentInputs: [['isActiveFlex' , 'For tile API call'], ['isExpandedFlex', 'For hiding content']],
    componentOutputs: [],
    componentName: 'opalerttile'
  };
  const opQuickLinkSharedComp: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders OP Video Tile.',
    componentInputs: [['isActiveFlex' , 'For tile API call'], ['isExpandedFlex', 'For hiding content']],
    componentOutputs: [],
    componentName: 'opquicklinktile'
  };
  const opProjectSharedComp: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders OP Project Tile.',
    componentInputs: [['isActiveFlex' , 'For tile API call'], ['isExpandedFlex', 'For hiding content']],
    componentOutputs: [],
    componentName: 'opprojecttile'
  };

  const opJobBoardTileSharedComp: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders OP JobBoard Tile.',
    componentInputs: [['isActiveFlex' , 'For tile API call'], ['isExpandedFlex', 'For hiding content']],
    componentOutputs: [],
    componentName: 'opjobboardtile'
  };
  const opCSASkillsMgmtV2: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders the CSA Skills Management Experience',
    componentInputs: ['isExperienceApplicable', 'isSkillTabLoaded'],
    componentOutputs: [],
    componentName: 'OpSkillsMgmtComponent'
  };
  const opMCSSkillsMgmt: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders the MCS Skills Management Experience',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'OpSkillMgmtContainerComponent'
  };

  const managerWbSkills: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders the Manager Workbench Skills ISD Experience',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'ManagerWbSkillsContainerComponent'
  };

  const mwbSkillsCes: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders the Manager Workbench Skills CES & Sales Experience',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'MwbSkillsCesContainerComponent'
  };

  const mwbrecommendationflyoutcontainer: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders the Manager Workbench Recommendation Flyout',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'MwbRecommendationFlyoutContainerComponent'
  };

  const opVgJobboardPage: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders the MCS Job board Experience',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'OpVergeJobboardPage'
  };

  const opPartnerSkillSearch: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders OP Partner Search',
    componentInputs: ['skillSearchKey', 'isDisabled', 'uiConfigData', 'filterConfigData', 'defaultSelectedRecords', 'placeHolderText', 'inputText', 'telemetryBag', 'recommendationsParams', 'isRequired'],
    componentOutputs: [],
    componentName: 'opPartnerSkillSearch'
  };

  const profileCompleteness: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders profile completeness popup',
    componentInputs: ['viewMode', 'viewerDetails'],
    componentOutputs: ['navigationClick', 'refreshParent'],
    componentName: 'profilecompleteness'
  };

  const myTeamMcsBaseComponent: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders base view for Manager Workbench',
    componentInputs: ['viewMode'],
    componentOutputs: ['navigationClick', 'refreshParent'],
    componentName: 'my-team-base-view'
  };

  const myTeamMcsHeaderTilesComponent: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders my team header tiles',
    componentInputs: ['workbenchContext', 'Source data to display in tiles'],
    componentOutputs: [],
    componentName: 'my-team-header-tiles'
  };

  const myTeamCesSalesBaseComponent: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders profile completeness popup',
    componentInputs: ['viewMode'],
    componentOutputs: ['navigationClick', 'refreshParent'],
    componentName: 'my-team-cessales-base-view'
  };

  const myTeamCesSalesHeaderTilesComponent: ISharedComponentConfig  = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders my team header tiles',
    componentInputs: ['workbenchContext', 'Source data to display in tiles'],
    componentOutputs: [],
    componentName: 'my-team-cessales-header-tiles'
  };

  const insightsCardComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-ConvergedDashboardModule-Bundle',
    componentDescription: 'This component renders mini card.',
    componentInputs: [], // optional: Provide the list of input parameters with description.
    componentOutputs: [], // optional: Provide the list of output parameters with description.
    componentName: 'insights-card',
  };

  const manageProfileComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-ConvergedDashboardModule-Bundle',
    componentDescription: 'This component renders mini card.',
    componentInputs: [], // optional: Provide the list of input parameters with description.
    componentOutputs: [], // optional: Provide the list of output parameters with description.
    componentName: 'app-op-ds-header-new',
  };

  const skillAssessmentCardComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-ConvergedDashboardModule-Bundle',
    componentDescription: 'This component renders mini card.',
    componentInputs: [], // optional: Provide the list of input parameters with description.
    componentOutputs: [], // optional: Provide the list of output parameters with description.
    componentName: 'app-op-ds-skill-tile-new',
  };
  const opVergeProjectHistoryDetailsTalentWbComp: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders project engagement history for Talent workbench',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'OpVergeProjectHistoryDetailsTalentWbComponent'
  };

  const opVergeProjectHistoryDetailsManagerWbComp: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders project engagement history for Manager Workbench',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'OpVergeProjectHistoryDetailsManagerWbComponent'
  };

  const opVergeProjectHistoryDetailsCESManagerWbComp: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders project engagement history for CES Manager Workspace',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'CESManagerProjectHistoryDetailsComponent'
  };

  const opAreaOfExpertiseSharedComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders expertise of an user',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'ModernAreaOfExpertiseComponent'
  };
  const opModernCreateProfileSharedComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders admin create profile component',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'ModernCreateProfileComponent'
  };
  const opModernBulkAccreditationManagementSharedComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders accredit management component',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'ModernAccreditationManagementComponent'
  };
  const opModernAutoAccreditationManagementSharedComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders admin auto accredit management component',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'ModernAutoAccreditationManagementComponent'
  };
  const opModernUpdateProfileSharedComponent: ISharedComponentConfig = {

generatedBundle: 'a4cefb120ff5-OPMainModule-Bundle',
    componentDescription: 'This component renders admin update profile component',
    componentInputs: [],
    componentOutputs: [],
    componentName: 'opModernUpdateProfileSharedComponent'
  };

    return {
    appName: 'oneprofileapplication',
      components: [grmCalendarSharedComp, opCertSharedComp, opSkillSharedComp, opProjectSharedComp, opQuickLinkSharedComp, opAlertSharedComp,
        opJobBoardTileSharedComp, opCSASkillsMgmtV2, opPartnerSkillSearch, opMCSSkillsMgmt, managerWbSkills, mwbSkillsCes,
        opVgJobboardPage, profileCompleteness, myTeamMcsHeaderTilesComponent, myTeamMcsBaseComponent, mwbrecommendationflyoutcontainer,
        opVergeProjectHistoryDetailsTalentWbComp, opVergeProjectHistoryDetailsManagerWbComp, insightsCardComponent, manageProfileComponent,
        skillAssessmentCardComponent, myTeamCesSalesBaseComponent, myTeamCesSalesHeaderTilesComponent, opVergeProjectHistoryDetailsCESManagerWbComp, opAreaOfExpertiseSharedComponent,opModernCreateProfileSharedComponent,opModernAutoAccreditationManagementSharedComponent,opModernBulkAccreditationManagementSharedComponent,opModernUpdateProfileSharedComponent],
    disableSharing: false,
    sharedBundles: []
    };
  }

 public getGeneratedBundles(){
 let currentScriptUrl = document.currentScript['src']; 
let baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/')); 
 return [
{
                name: 'a4cefb120ff5-OPMainModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/OPMainModule.vendor.bundle.js',
                    baseUrl + '/OPMainModule.bundle.js'
                ],
                sequentialLoading: true           
            },
{
                name: 'a4cefb120ff5-ConvergedDashboardModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ConvergedDashboardModule.vendor.bundle.js',
                    baseUrl + '/ConvergedDashboardModule.bundle.js'
                ],
                sequentialLoading: true           
            },

]
 
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(OneProfileApplication_routes, 'oneprofileapplication');
